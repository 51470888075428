import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FoulingMeasurement, Fouling, CoatingData } from 'types/models/Fouling';

const initialState/* : Fouling */ = {
    foulingMeasurementData: {
        data: null,
        status: 'idle',
        error: null
    },
    coatingData: {
        data: null,
        status: 'idle',
        error: null
    },
    foulingCleaningActivities: {
        data: null,
        status: 'idle',
        error: null
    },
    foulingAccumulation: {
        data: null,
        status: 'idle',
        error: null
    }

};

const foulingSlice = createSlice({
    name: 'fouling',
    initialState,
    reducers: {
        loadFoulingMeasurementStart(state) {
            state.foulingMeasurementData.status = 'loading';
        },
        loadFoulingMeasurementSuccess(state, action: PayloadAction<FoulingMeasurement>) {
            state.foulingMeasurementData.status = 'succeeded';
            state.foulingMeasurementData.data = action.payload;
            state.foulingMeasurementData.error = null;
        },
        loadFoulingMeasurementFailure(state, action: PayloadAction<any>) {
            state.foulingMeasurementData.status = 'error';
            state.foulingMeasurementData.error = action.payload;
        },

        loadFoulingCleaningActivitiesStart(state) {
            state.foulingCleaningActivities.status = 'loading';
        },
        loadFoulingCleaningActivitiesSuccess(state, action) {
            state.foulingCleaningActivities.status = 'succeeded';
            state.foulingCleaningActivities.data = action.payload;
            state.foulingCleaningActivities.error = null;
        },
        loadFoulingCleaningActivitiesFailure(state, action: PayloadAction<any>) {
            state.foulingCleaningActivities.status = 'error';
            state.foulingCleaningActivities.error = action.payload;
        },

        loadFoulingAccumulationStart(state) {
            state.foulingAccumulation.status = 'loading';
        },
        loadFoulingAccumulationSuccess(state, action) {
            state.foulingAccumulation.status = 'succeeded';
            state.foulingAccumulation.data = action.payload;
            state.foulingAccumulation.error = null;
        },
        loadFoulingAccumulationFailure(state, action: PayloadAction<any>) {
            state.foulingAccumulation.status = 'error';
            state.foulingAccumulation.error = action.payload;
        },

        loadCoatingDataStart(state) {
            state.coatingData.status = 'loading';
        },
        loadCoatingDataSuccess(state, action: PayloadAction<CoatingData>) {
            state.coatingData.status = 'succeeded';
            state.coatingData.data = action.payload;
            state.coatingData.error = null;
        },
        loadCoatingDataFailure(state, action: PayloadAction<any>) {
            state.coatingData.status = 'error';
            state.coatingData.error = action.payload;
        }
    },
});

export const {
    loadFoulingMeasurementStart,
    loadFoulingMeasurementSuccess,
    loadFoulingMeasurementFailure,

    loadFoulingCleaningActivitiesStart,
    loadFoulingCleaningActivitiesSuccess,
    loadFoulingCleaningActivitiesFailure,

    loadFoulingAccumulationStart,
    loadFoulingAccumulationSuccess,
    loadFoulingAccumulationFailure,

    loadCoatingDataStart,
    loadCoatingDataSuccess,
    loadCoatingDataFailure
} = foulingSlice.actions;

export default foulingSlice.reducer;
