import { alpha } from "@mui/material";
import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutDirection,
  LayoutType,
  MenuStyle,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from "shared/constants/AppEnums";

export const textLight = {
  primary: "rgb(17, 24, 39)",
  secondary: "rgb(107, 114, 128)",
  disabled: "rgb(149, 156, 169)",
};

export const textDark = {
  primary: "#F5F5F5", //gray.main
  secondary: "#ACAEB0", //Hit Grey
  light: "#EBEBEB", //Soft White
  disabled: "#00406C", //Marine
};

export const backgroundDark = {
  primary: "#003356", //Blue Whale
  secondary: "#002945", //Midnight
  tertiary: "#002137", //Dark Navy
  paper: "#001A2C", //Dark Midnight
  default: "#00111C" //Blue Charcoal
};

export const backgroundLight = {
  paper: "#FFFFFF",
  default: "#2B3B55",
};

const cardRadius = ThemeStyleRadius.STANDARD;
export const defaultTheme: any = {
  theme: {
    spacing: 4,
    cardRadius: cardRadius,
    direction: LayoutDirection.LTR, //ltr, rtl
    palette: {
      mode: ThemeMode.DARK,
      background: backgroundDark,
      divider: "#00111C",
      primary: {
        main: "#2599FC", //azure
        light: "#1D71B8", //canopus
        dark: "#00406C", //marine
      },
      secondary: {
        main: "#80E7FF", //lagoon
        light: "#13EAC9", //carinae
        dark: "#03C0D4", //turquoise
      },
      success: {
        main: "#08EA7D", //Green Teal 
        light: "#D9F5E5"
      },
      other: {
        purple: "#5E2BFF",
        redPink: "#FF3368",
        yellow: "#FFFF5C"
      },
      warning: {
        main: "#F41515", //Lava

        green: "#06BA63", //green
        greenLight: "#75E5A7", //lime
        yellow: "#F7B801",
        orange: "#ED7D31",
        red: "#F41515", //Lava

        // purple: "#5E2BFF",
        // light: "#FFECDC",
      },
      status: {
        danger: "#F41515", //Lava
      },
      common: {
        white: "#FFFFFF",
        black: "#000000",
      },

      lagoon: "#80E7FF",
      // tertiary: { main: "#2599FC", contrastText: "#fff" },
      turquoise: { main: "#03C0D4" },
      yellow: { main: "#FFFF5C" },
      orange: { main: "#ED7D31" },
      azure: { main: "#2599FC", contrastText: "#fff" },
      carinae: { main: "#13EAC9", contrastText: "#fff" },
      marine: { main: "#00406C", contrastText: "#fff" },
      canopus: { main: "#1D71B8", contrastText: "#fff" },

      text: textDark,
      gray: {
        main: "#F5F5F5",
        secondary: "#ACAEB0",
        50: "#fafafa",
        100: "#F5F6FA",
        200: "#edf2f7",
        300: "#E0E0E0",
        400: "#c5c6cb",
        500: "#A8A8A8",
        600: "#666666",
        700: "#4a5568",
        800: "#201e21",
        900: "#1a202c",
        A100: "#d5d5d5",
        A200: "#aaaaaa",
        A400: "#303030",
        A700: "#616161",
      },
      blue: {
        main: "#2599FC",
        dark: "#1E293B",
        whale: "#003356",
        charcoal: "#00111C",
        navy: '#002137',
        contrastText: "#fff"
      },
    },

    typography: {
      fontFamily: ["CFAstyStd", "PFSquareSansPro", "sans-serif"].join(","),
      fontSize: 14,
      fontWeight: 400,
      h1: {
        fontSize: 22,
        fontWeight: 600,
      },
      h2: {
        fontSize: 20,
        fontWeight: 500,
      },
      h3: {
        fontSize: 20,
        fontWeight: 400,
        color: '#F5F5F5',
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
      },
      h6: {
        fontSize: 12,
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 14,
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 400,
      },
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      body2: {
        fontSize: 12,
      },
    },
    shadows: [
      'none', // '0dp' equivalent, no shadow
      '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',// '1dp'
      '0px 2px 3px 0px #0000004D,  0px 4px 4px 0px #00000040', // '2dp'
      '0px 4px 4px 0px rgba(0, 0, 0, 0.3),0px 8px 12px 6px rgba(0, 0, 0, 0.15)',
      'none', 'none', 'none', 'none', 'none', 'none', 'none', 'none',
      // Add other shadows as needed
    ],
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
          },
        },
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            // apply theme's border-radius instead of component's default
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD
                ? ThemeStyleRadius.STANDARD
                : ThemeStyleRadius.MODERN + 20,
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            backgroundImage: "none",
            borderRadius: cardRadius,
            boxShadow: "0px 10px 10px 4px rgba(0, 0, 0, 0.04)",
            "& .MuiCardContent-root:last-of-type": {
              paddingBottom: 16,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            // boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
            textTransform: "capitalize",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 9,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: Fonts.REGULAR,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: '#2599FC',
          },
        },
      },
      MuiTextFild: {
        styleOverrides: {
          root: {
            borderWidth: '1px',
            borderRadius: cardRadius / 4,
          },
          '&.Mui-disabled': {
            // Styles applied to the root element if `disabled={true}`
            backgroundColor: 'green', // Example: change background color when disabled
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            color: '#ACAEB0',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#ACAEB0',
            },
            '&.Mui-disabled': {
              color: '#1D71B8', // Change text color when disabled
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1D71B8', // Change border color when disabled
              },
            },
            '&.Mui-focused': {
              color: 'white',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#00406C',
                borderWidth: '2px',
              },
            },
          },
          input: {
            '&.Mui-disabled': {
              '-webkit-text-fill-color': 'inherit', // remove browser's text color when disabled
            }
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#ACAEB0',
            '&.Mui-focused': {
              color: 'white'
            },
            '&.Mui-disabled': {
              color: '#1D71B8', // Change label color when disabled
            },
          },
        },
      },
    },
  },
};

export interface SidebarData {
  sidebarBgColor: string;
  sidebarTextColor: string;
  sidebarHeaderColor: string;
  sidebarMenuHoveredBgColor?: string;
  sidebarMenuHoveredTextColor?: string;
  sidebarMenuSelectedBgColor: string;
  sidebarMenuSelectedTextColor: string;
  mode: string;
}

export const DarkSidebar: SidebarData = {
  sidebarBgColor: backgroundDark.paper,
  sidebarTextColor: textDark.secondary,
  sidebarHeaderColor: backgroundDark.paper,
  sidebarMenuHoveredBgColor: alpha(defaultTheme.theme.palette.primary.dark, 0.6),
  sidebarMenuHoveredTextColor: defaultTheme.theme.palette.common.white,
  sidebarMenuSelectedBgColor: /* alpha(defaultTheme.theme.palette.primary.main, 0.1) */'transparent',
  sidebarMenuSelectedTextColor: defaultTheme.theme.palette.primary.main,
  mode: ThemeMode.DARK,
};
export const LightSidebar: SidebarData = {
  sidebarBgColor: "#fff",
  sidebarTextColor: "rgba(0, 0, 0, 0.60)",
  sidebarHeaderColor: "#fff",
  sidebarMenuSelectedBgColor: "#1E293B",
  sidebarMenuSelectedTextColor: "rgba(0, 0, 0, 0.87)",
  mode: ThemeMode.LIGHT,
};
const defaultConfig = {
  sidebar: {
    borderColor: "#757575",
    menuStyle: MenuStyle.DEFAULT,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: DarkSidebar,
  },
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.DARK,
  navStyle: NavStyle.HEADER_USER,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.FIXED,
  rtAnim: RouteTransition.NONE,
  footer: false,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
  rtlLocale: ["ar"],
};
export default defaultConfig;
