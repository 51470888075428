import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FoulingOperationCycleData } from 'types/models/BioFouling';

const foulingOperationalCycleAdapter = createEntityAdapter({
    selectId: (operationCycle: FoulingOperationCycleData) => operationCycle?.operationalCycleId,
});

const initialState = foulingOperationalCycleAdapter.getInitialState({
    pagination: null,
    status: 'idle',
    updateStatus: 'idle',
    error: null,
    updateError: null,
    operationCycleById: {
        data: null,
        status: 'idle',
        error: null,
    }
});

const foulingOperationalCycleSlice = createSlice({
    name: 'foulingOperationCycle',
    initialState,
    reducers: {
        loadFoulingOperationalCycleStart(state) {
            state.status = 'loading';
        },
        loadFoulingOperationalCycleSuccess(state, action: PayloadAction<any>) {
            const {
                data,
                totalPages,
                totalRecords,
                pageNumber,
                pageSize,
                firstPage,
                lastPage,
                nextPage,
                previousPage
            } = action.payload;
            foulingOperationalCycleAdapter.setAll(state, data);
            state.pagination = { pageNumber, pageSize, totalPages, totalRecords, firstPage, lastPage, nextPage, previousPage };
            state.status = 'succeeded'
        },
        loadFoulingOperationalCycleFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        loadFoulingOperationalCycleByIdStart(state) {
            state.operationCycleById.status = 'loading';
        },
        loadFoulingOperationalCycleByIdSuccess(state, action) {
            state.operationCycleById.status = 'succeeded';
            state.operationCycleById.data = action.payload;
            state.operationCycleById.error = null;
        },
        loadFoulingOperationalCycleByIdFailure(state, action: PayloadAction<string>) {
            state.operationCycleById.status = 'error';
            state.operationCycleById.error = action.payload;
        },


        // post - put
        setFoulingOperationalCycleStart(state) {
            state.updateStatus = 'loading';
        },
        setFoulingOperationalCycleSuccess(state, action) {
            state.updateStatus = 'succeeded';
            state.operationCycleById = action.payload;
            state.updateError = null;
        },
        setFoulingOperationalCycleFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        // delete
        deleteFoulingOperationalCycleStart(state) {
            state.updateStatus = 'loading';
        },
        deleteFoulingOperationalCycleSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        deleteFoulingOperationalCycleFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.error = action.payload;
        },

        resetFoulingOperationalCycleUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    }
});

export const {
    loadFoulingOperationalCycleStart,
    loadFoulingOperationalCycleSuccess,
    loadFoulingOperationalCycleFailure,

    loadFoulingOperationalCycleByIdStart,
    loadFoulingOperationalCycleByIdSuccess,
    loadFoulingOperationalCycleByIdFailure,

    setFoulingOperationalCycleStart,
    setFoulingOperationalCycleSuccess,
    setFoulingOperationalCycleFailure,

    deleteFoulingOperationalCycleStart,
    deleteFoulingOperationalCycleSuccess,
    deleteFoulingOperationalCycleFailure,

    resetFoulingOperationalCycleUpdateStatus,
} = foulingOperationalCycleSlice.actions;

// Exporting selectors to access the state
export const foulingOperationalCycleSelectors = foulingOperationalCycleAdapter.getSelectors<RootState>(
    (state) => state.foulingOperationCycle
);

export default foulingOperationalCycleSlice.reducer;