import { styled } from '@mui/system';
import { CremaTheme } from 'types/AppContextPropsType';
import { MenuItem, Select, Stack, StackProps } from '@mui/material';

interface RowStackProps extends StackProps { }

export const RowStack : React.FC<RowStackProps> = (props) => {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={3}
            {...props}
        >
            {props.children}
        </Stack>
    );
};


export const SelectBox = styled(Select)(({ theme }: { theme: CremaTheme }) => ({
    border: 0,
    borderRadius: theme.spacing(1),
    paddingRight: 0,
    "& .MuiSelect-select": {
        fontSize: 14,
        border: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: 56,
        minWidth: 58,
        backgroundColor: theme.palette.primary.dark,
        padding: 0,
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.light
        },
    },
    "& .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
        padddingRight: 0
    },
    "& .MuiSelect-icon": {
        color: theme.palette.text.light,
        fontSize: 10,
        marginRight: theme.spacing(1),
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
    },
    "&.Mui-focused": {
        border: 0,
        "& .MuiOutlinedInput-notchedOutline": {
            border: 0,
        },
    },
    '& .MuiPaper-root': {
        marginTop: 2,
        backgroundColor: theme.palette.primary.dark,
        backgroundImage: 'none',
    },
}));


export const CustomMenuItem = styled(MenuItem)(({ theme }: { theme: CremaTheme }) => ({
    height: 56,
    '&:hover': {
        color: theme.palette.text.light,
        backgroundColor: theme.palette.primary.light
    },
    '&.Mui-selected': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.light
        }
    },
}));