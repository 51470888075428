import React, { useState, useEffect } from "react";
import { IconButton, Popover, Badge } from "@mui/material";
import NotificationContent from "./NotificationContent";
import { CremaTheme } from "types/AppContextPropsType";
import { ReactComponent as NotificationIcon } from "../../assets/icon/notifications.svg";
import { getNotifications } from '../../redux/actions/Notifications';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useLocation } from "react-router-dom";
import ErrorDisplayCard from 'components/ApiHandlers/Error';
import SkeletonLoader from 'components/ApiHandlers/Loader/Skeleton';
import IntlMessages from "@crema/utility/IntlMessages";

const Notifications: React.FC = () => {
    const { pathname } = useLocation();
    const dispatch = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const selectedShip = useAppSelector((state) => state.ships?.selectedShip);
    const { status: notificationsStatus, error: errorMsg } = useAppSelector((state) => state?.notifications);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const isNoficationPage = pathname === '/notifications'
    const totalNotifications = 10;

    useEffect(() => {
        if (selectedShip?.shipId && open) {
            dispatch(getNotifications(selectedShip.shipId, 1, totalNotifications));
        }
    }, [dispatch, selectedShip?.shipId, open]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <IconButton
                className="icon-btn"
                sx={{
                    borderRadius: "50%",
                    width: 40,
                    height: 40,
                    color: "white",
                    '&:hover, &:focus , &:active': {
                        border: 0,
                        bgcolor: 'transparent'
                    },
                    '&: disabled': {
                        color: 'background.secondary'
                    }
                }}
                disabled={isNoficationPage}
                onClick={handleClick}
                size="small"
            >
                {/* <Badge
                    badgeContent={4}
                    color="error"
                    variant="dot"
                    overlap="circular"
                    invisible={isNoficationPage}
                > */}
                    <NotificationIcon />
                {/* </Badge> */}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        mt: 6,
                        ml: 13,
                        width: 380,
                        boxShadow: (theme: CremaTheme) => theme.shadows[2],
                    }
                }}
            >
                {notificationsStatus === 'succeeded' || "idle" ? (
                    <NotificationContent handleClose={handleClose} />
                ) : notificationsStatus === 'loading' ? (
                    <SkeletonLoader skeletonProps={{ variant: 'rounded' }} />
                ) : notificationsStatus === 'error' && (
                    <ErrorDisplayCard
                        flexContainer={{ gap: 4, py: 20 }}
                        sxStyle={{ bgcolor: "background.primary" }}
                        iconContainer={{ height: 50, width: 50 }}
                        typographyProps={{ variant: 'body1', color: 'common.white' }}
                    >
                        {errorMsg || <IntlMessages id="message.somethingWentWrong" />}
                    </ErrorDisplayCard>
                )}
            </Popover >
        </>
    );
};

export default Notifications;