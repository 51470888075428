import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FoulingActivityData } from 'types/models/BioFouling';

const foulingActivitiesAdapter = createEntityAdapter({
    selectId: (activity: FoulingActivityData) => activity?.activityId,
});

const initialState = foulingActivitiesAdapter.getInitialState({
    status: 'idle',
    updateStatus: 'idle',
    error: null,
    updateError: null
});

const foulingActivitiesSlice = createSlice({
    name: 'foulingActivities',
    initialState,
    reducers: {
        loadFoulingActivitiesStart(state) {
            state.status = 'loading';
        },
        loadFoulingActivitiesSuccess(state, action) {
            state.status = 'succeeded';
            foulingActivitiesAdapter.setAll(state, action.payload.data);
            state.error = null;
        },
        loadFoulingActivitiesFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        // post - put
        setFoulingActivitiesStart(state) {
            state.updateStatus = 'loading';
        },
        setFoulingActivitiesSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        setFoulingActivitiesFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        // delete
        deleteFoulingActivitiesStart(state) {
            state.updateStatus = 'loading';
        },
        deleteFoulingActivitiesSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        deleteFoulingActivitiesFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.error = action.payload;
        },

        resetFoulingActivitiesUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    }
});

export const {
    loadFoulingActivitiesStart,
    loadFoulingActivitiesSuccess,
    loadFoulingActivitiesFailure,

    setFoulingActivitiesStart,
    setFoulingActivitiesSuccess,
    setFoulingActivitiesFailure,

    deleteFoulingActivitiesStart,
    deleteFoulingActivitiesSuccess,
    deleteFoulingActivitiesFailure,

    resetFoulingActivitiesUpdateStatus
} = foulingActivitiesSlice.actions;


export const foulingActivitiesSelectors = foulingActivitiesAdapter.getSelectors<RootState>(
    (state) => state.foulingActivities
);

export default foulingActivitiesSlice.reducer;