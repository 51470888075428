import React from "react";
import { useThemeContext } from "../../../../utility/AppContextProvider/ThemeContextProvider";
import { alpha, Box } from "@mui/material";
import { ReactComponent as LogoText } from "../../../../../assets/icon/logo_text.svg";
import { ReactComponent as VerdeLogoText } from "../../../../../assets/icon/verde_logo_text.svg";

interface AppLogoProps {
  color?: string;
}

const AppLogo: React.FC<AppLogoProps> = ({ color }) => {
  const { theme } = useThemeContext();

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        display: "flex",
        flexDirection: "row",
        cursor: "auto",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          height: theme => theme.spacing(8),
          pr: 0.5,
        },
      }}
      className="app-logo"
    >
      <LogoText fill={alpha(theme.palette.text.primary, 0.8)} />
      {/* <VerdeLogoText fill={alpha(theme.palette.text.primary, 0.8)} /> */}
    </Box>
  );
};

export default AppLogo;
