import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FoulingProductTypesData } from 'types/models/BioFouling';

const initialState = {
    data : null,
    status: 'idle',
    updateStatus: 'idle',
    error: null,
    updateError: null
}

const foulingProductsTypesSlice = createSlice({
    name: 'foulingProductsTypes',
    initialState,
    reducers: {
        loadFoulingProductsTypesStart(state) {
            state.status = 'loading';
        },
        loadFoulingProductsTypesSuccess(state, action) {
            state.data = action.payload;
            state.status = 'succeeded'
        },
        loadFoulingProductsTypesFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        // post - put
        setFoulingProductsTypesStart(state) {
            state.updateStatus = 'loading';
        },
        setFoulingProductsTypesSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        setFoulingProductsTypesFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        // delete
        deleteFoulingProductsTypesStart(state) {
            state.updateStatus = 'loading';
        },
        deleteFoulingProductsTypesSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        deleteFoulingProductsTypesFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.error = action.payload;
        },

        resetFoulingProductsTypesUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    }
});

export const {
    loadFoulingProductsTypesStart,
    loadFoulingProductsTypesSuccess,
    loadFoulingProductsTypesFailure,

    setFoulingProductsTypesStart,
    setFoulingProductsTypesSuccess,
    setFoulingProductsTypesFailure,

    deleteFoulingProductsTypesStart,
    deleteFoulingProductsTypesSuccess,
    deleteFoulingProductsTypesFailure,

    resetFoulingProductsTypesUpdateStatus
} = foulingProductsTypesSlice.actions;

export default foulingProductsTypesSlice.reducer;