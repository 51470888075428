import React, { useState } from 'react'
import { Typography, Stack, Divider, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from "@crema/utility/IntlMessages";
import NotificationCalendar from './Calendar';
import { resetNotificationsConfigUpdateStatus } from '../../redux/reducers/NotificationsConfig';
import { getNotificationConfig } from '../../redux/actions/NotificationConfig';
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getClientTimezone } from 'utils/dateUtils';
import AppSwitch from '@crema/core/AppFormComponents/AppSwitch';

interface NotificationPreferencesProp {
    handleBack: () => void;
}

const NotificationPreferences: React.FC<NotificationPreferencesProp> = ({ handleBack }) => {
    const dispatch = useAppDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [muted, setMute] = useState(true);

    const selectedShip = useAppSelector((state) => state.ships?.selectedShip);

    const clientTimezone = getClientTimezone();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMute(event.target.checked);
    };

    const handleNotifCalendarShow = () => {
        dispatch(resetNotificationsConfigUpdateStatus());
        dispatch(getNotificationConfig(selectedShip?.shipId, clientTimezone));
        setIsOpen(true);
    };

    const handleNotifCalendarClose = () => {
        setIsOpen(false);
    }

    return (
        <Stack
            divider={<Divider aria-hidden="true" flexItem sx={{ borderColor: "primary.dark" }} />}
            sx={{
                width: "100%",
                height: 180,
                bgcolor: "background.primary",
                minHeight: { xs: 56, sm: 70 },
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="start" mx={2} gap={2} sx={{ height: 1 / 3 }}>
                <IconButton
                    sx={{ color: "white", "&:hover": { backgroundColor: "primary.dark", } }}
                    onClick={handleBack}
                >
                    <ArrowBackIcon />
                </IconButton>
                <Typography component="h5" variant='h5' color='white'>
                    <IntlMessages id="common.preferences" />
                </Typography>
            </Stack>


            <Button
                sx={{
                    height: 1 / 3,
                    py: 0, pl: 4,
                    justifyContent: 'start',
                    borderRadius: 0,
                    '&:hover': { bgcolor: 'primary.dark', }
                }}
                onClick={handleNotifCalendarShow}
            >
                <Typography variant='body1' color='common.white'>
                    <IntlMessages id="common.scheduleNotifications" />
                </Typography>
            </Button>


            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ height: 1 / 3, p: 0 }}
                mx={4}
            >
                <Typography variant='body1' color='common.white'>
                    <IntlMessages id="common.muteNotifications" />
                </Typography>

                <AppSwitch
                    checked={muted}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </Stack>

            <NotificationCalendar open={isOpen} onClose={handleNotifCalendarClose} />
        </Stack >
    );
};

export default NotificationPreferences;