import React, { ReactNode } from "react";
import { RoutePermittedRole } from "../shared/constants/AppConst";
import { ReactComponent as Overview } from "../assets/icon/overview.svg";
import { ReactComponent as DataInput } from "../assets/icon/data-input.svg";
import { ReactComponent as Srm } from "../assets/icon/srm-page.svg";
import { ReactComponent as Compliance } from "../assets/icon/compliance.svg";
import { ReactComponent as Vessel } from "../assets/icon/voyage-optimazation.svg";
import { ReactComponent as Fouling } from "../assets/icon/fouling.svg"

export interface RouterConfigData {
  id: string;
  title: string;
  messageId: string;
  icon?: string | ReactNode;
  type: "item" | "group" | "collapse" | "divider";
  children?: RouterConfigData[];
  permittedRole?: RoutePermittedRole[];
  color?: string;
  url?: string;
  exact?: boolean;
  count?: number;
}
const routesConfig: RouterConfigData[] = [
  {
    id: "data-input",
    title: "dataInput",
    messageId: "sidebar.sample.dataInput",
    type: "item",
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    icon: <DataInput />,
    url: "/seaquest/data-input",
    children: [
      {
        id: "form-noon-eport",
        title: "Form Noon Report",
        messageId: "sidebar.sample.formNoonReport",
        type: "item",
        url: "/seaquest/data-input/noon-report-submission-form",
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
      },
      {
        id: "excel-noon-eport",
        title: "Excel Noon Report",
        messageId: "sidebar.sample.noonReport",
        type: "item",
        url: "/seaquest/data-input/excel-noon-report",
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
      },
      {
        id: "upload-files",
        title: "Excel Noon Report",
        messageId: "sidebar.sample.uploadFiles",
        type: "item",
        url: "/seaquest/data-input/upload-files",
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
      },
      {
        id: "biofouling-managment-plan",
        title: "BiofoulingManagmentPlan",
        messageId: "sidebar.sample.uploadFiles",
        type: "item",
        url: "/seaquest/data-input/biofouling-plan",
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
        children: [
          {
            id: "dry-dock",
            title: "DryDock",
            messageId: "sidebar.sample.dryDock",
            type: "item",
            url: "/seaquest/data-input/biofouling-plan/dry-dock/:id?",
            permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
          },
          {
            id: "diver-operations",
            title: "DiverOperations",
            messageId: "sidebar.sample.diverOperations",
            type: "item",
            url: "/seaquest/data-input/biofouling-plan/diver-operations/:id?",
            permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
          },
          {
            id: "crew-operations",
            title: "CrewOperations",
            messageId: "sidebar.sample.crewOperations",
            type: "item",
            url: "/seaquest/data-input/biofouling-plan/crew-operations/:id?",
            permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
          },
        ],
      },
    ]
  },
  {
    id: "overview",
    title: "Overview",
    messageId: "sidebar.sample.overview",
    type: "item",
    permittedRole: [RoutePermittedRole.Admin],
    icon: <Overview />,
    url: "/seaquest/overview",
  },
  {
    id: "srm",
    title: "srm",
    messageId: "sidebar.sample.srm",
    type: "item",
    icon: <Srm />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Demo, RoutePermittedRole.Admin],
    url: "/seaquest/rcm",
  },
  {
    id: "compliance",
    title: "Compliance",
    messageId: "sidebar.sample.compliance",
    type: "item",
    permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
    icon: <Compliance />,
    url: "/seaquest/ship-performance",
  },
  {
    id: "vessel-decarbonization",
    title: "vesselDecarbonization",
    messageId: "sidebar.sample.vesselDecarbonization",
    type: "item",
    icon: <Vessel />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    url: "/seaquest/ship-dashboard",
  },
  {
    id: "fouling",
    title: "Fouling",
    messageId: "sidebar.sample.fouling",
    type: "item",
    icon: <Fouling />,
    permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
    url: "/seaquest/fouling",
  },
];
export default routesConfig;
