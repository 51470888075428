import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FoulingProductData } from 'types/models/BioFouling';

const foulingProductsAdapter = createEntityAdapter({
    selectId: (product: FoulingProductData) => product?.productId,
});

const initialState = foulingProductsAdapter.getInitialState({
    pagination: null,
    status: 'idle',
    updateStatus: 'idle',
    error: null,
    updateError: null,
    product: {
        data: null,
        status: 'idle',
        error: null
    }
});

const foulingProductsSlice = createSlice({
    name: 'foulingProducts',
    initialState,
    reducers: {
        loadFoulingProductsStart(state) {
            state.status = 'loading';
        },
        loadFoulingProductsSuccess(state, action) {
            const {
                data,
                totalPages,
                totalRecords,
                pageNumber,
                pageSize,
                firstPage,
                lastPage,
                nextPage,
                previousPage
            } = action.payload;
            foulingProductsAdapter.setAll(state, data);
            state.pagination = { pageNumber, pageSize, totalPages, totalRecords, firstPage, lastPage, nextPage, previousPage };
            state.status = 'succeeded'
        },
        loadFoulingProductsFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        loadFoulingProductByIdStart(state) {
            state.product.status = 'loading';
        },
        loadFoulingProductByIdSuccess(state, action) {
            state.product.data = action.payload;
            state.product.status = 'succeeded';
        },
        loadFoulingProductByIdFailure(state, action: PayloadAction<string>) {
            state.product.status = 'error';
            state.product.error = action.payload;
        },

        // post - put
        setFoulingProductsStart(state) {
            state.updateStatus = 'loading';
        },
        setFoulingProductsSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        setFoulingProductsFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        // delete
        deleteFoulingProductsStart(state) {
            state.updateStatus = 'loading';
        },
        deleteFoulingProductsSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        deleteFoulingProductsFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.error = action.payload;
        },

        resetFoulingProductsUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    }
});

export const {
    loadFoulingProductsStart,
    loadFoulingProductsSuccess,
    loadFoulingProductsFailure,

    setFoulingProductsStart,
    setFoulingProductsSuccess,
    setFoulingProductsFailure,

    deleteFoulingProductsStart,
    deleteFoulingProductsSuccess,
    deleteFoulingProductsFailure,

    resetFoulingProductsUpdateStatus,

    loadFoulingProductByIdStart,
    loadFoulingProductByIdSuccess,
    loadFoulingProductByIdFailure,
} = foulingProductsSlice.actions;

// Exporting selectors to access the state
export const foulingProductsSelectors = foulingProductsAdapter.getSelectors<RootState>(
    (state) => state.foulingProducts
);

export default foulingProductsSlice.reducer;