import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Box, Theme, styled, IconButton } from "@mui/material";
import List from "@mui/material/List";
import IntlMessages from "@crema/utility/IntlMessages";
import NotificationItem from ".//NotificationItem";
import NotificationPreferences from "./NotificationPreferences";
import AppTooltip from "@crema/core/AppTooltip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { SxProps } from "@mui/system";
import { ReactComponent as PreferencesIcon } from "assets/icon/settings.svg";
import NoContentCard from "components/ApiHandlers/NoContent";
import { useAppSelector } from "../../redux/hooks";
import { notificationsSelectors } from "../../redux/reducers/Notifications";

const ScrollableContainer = styled(Box)(() => ({
  width: '100%',
  overflowY: 'auto',
  '::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for WebKit browsers
  '-ms-overflow-style': 'none',  // Hide scrollbar for IE and Edge
  'scrollbar-width': 'none',  // Hide scrollbar for Firefox 
}));

interface NotificationContentProps {
  sxStyle?: SxProps<Theme>;
  handleClose?: () => void;
}

const NotificationContent: React.FC<NotificationContentProps> = ({
  sxStyle,
  handleClose
}) => {
  const navigate = useNavigate();
  const [isPrefencesView, setIsPrefencesView] = useState(false);
  const notificationsList = useAppSelector(notificationsSelectors.selectAll);
  const notificationsCount = notificationsList?.length;

  if (isPrefencesView) return <NotificationPreferences handleBack={() => setIsPrefencesView(false)} />;

  const handleViewAll = () => {
    handleClose();
    navigate('/notifications')
  }
  return (
    <Stack
      divider={<Divider aria-hidden="true" flexItem sx={{ borderColor: "primary.dark" }} />}
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "background.tertiary",
        minHeight: { xs: 56, sm: 70 },
        ...sxStyle,
      }}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" mx={4} py={5} >
        <Typography component="h5" variant='h5' color='white'>
          <IntlMessages id="common.notifications" />
        </Typography>
        <AppTooltip
          placement="left-start"
          title={<IntlMessages id="common.preferences" />}
          arrow={false}
          mode="dark"
        >
          <IconButton
            size="small"
            onClick={() => setIsPrefencesView(true)}
            sx={{
              color: 'text.light',
              p: 0,
              '&:hover': { bgcolor: 'transparent', color: 'white' },
            }}>
            <PreferencesIcon />
          </IconButton>
        </AppTooltip>
      </Stack>

      <ScrollableContainer sx={{ maxHeight: 315, bgcolor: "background.tertiary", }}>
        {notificationsCount > 0 ? (
          <List disablePadding>
            {notificationsList?.map((notification) => (
              <NotificationItem
                key={notification.notificationId}
                notification={notification}
                handleClose={handleClose}
              />
            ))}
          </List>
        ) : notificationsCount === 0 && (
          <NoContentCard flexContainer={{ py: 5, gap: 2 }}
            sxStyle={{ textAlign: 'center' }}
            iconContainer={{ fontSize: 80, color: 'text.secondary' }}
            title={<IntlMessages id="common.noNotificationsAvailable" />}
            message={<IntlMessages id="common.noNotificationsMessage" />}
          />
        )}
      </ScrollableContainer>


      {notificationsCount > 0 &&
        <Box sx={{
          height: 48,
          width: '100%',
          bgcolor: "background.tertiary",
          position: 'sticky',
          bottom: 0,
          display: "flex",
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
          <Typography
            component="span"
            variant='body2'
            color='text.light'
            sx={{
              cursor: 'pointer',
              mr: 8
            }}
            onClick={handleViewAll}
          >
            <IntlMessages id="common.viewAll" />
          </Typography>
        </Box>
      }
    </Stack>
  );
};

export default NotificationContent;