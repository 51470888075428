import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { ShipArea } from 'types/models/Ship';

const shipAreasAdapter = createEntityAdapter({
    selectId: (shipArea: ShipArea) => shipArea?.locationId,
});

const initialState = shipAreasAdapter.getInitialState({
    status: 'idle',
    error: null,
});

const shipAreasSlice = createSlice({
    name: 'shipAreas',
    initialState,
    reducers: {
        loadShipAreasStart(state) {
            state.status = 'loading';
        },
        loadShipAreasSuccess(state, action) {
            state.status = 'succeeded';
            shipAreasAdapter.setAll(state, action.payload.data);
            state.error = null;
        },
        loadShipAreasFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },
    }
});

export const {
    loadShipAreasStart,
    loadShipAreasSuccess,
    loadShipAreasFailure,
} = shipAreasSlice.actions;


export const shipAreasSelectors = shipAreasAdapter.getSelectors<RootState>(
    (state) => state.shipAreas
);

export default shipAreasSlice.reducer;