import React from "react";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import WarningIcon from "@mui/icons-material/Warning";
import Snackbar from "@mui/material/Snackbar";
import { Slide } from "@mui/material";
import { alpha } from "@mui/material";
import { CremaTheme } from "types/AppContextPropsType";

const StyledSnackbar = styled(Snackbar)(({ theme }: { theme: CremaTheme }) => ({
  "& .success": {
    backgroundColor: alpha(theme.palette.secondary.light, 0.2),
    color: theme.palette.secondary.light,
  },

  "& .error": {
    backgroundColor: alpha(theme.palette.status.danger, 0.35),
    color: theme.palette.status.danger,
  },

  "& .info": {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: theme.palette.primary.main,
  },

  "& .warning": {
    backgroundColor: alpha(theme.palette.other.yellow, 0.15),
    color: theme.palette.other.yellow,
  },

  "& .icon": {
    fontSize: 20,
  },

  "& .iconVariant": {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },

  "& .message": {
    display: "flex",
    alignItems: "center",
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

function TransitionLeft(props: any) {
  return <Slide {...props} direction="left" />;
}

interface AppMessageViewProps {
  className?: string;
  message: string;
  variant: "success" | "error" | "warning" | "info";
  open?: boolean;
  onClose?: () => void;
  [x: string]: any;
}

const AppMessageView: React.FC<AppMessageViewProps> = (props) => {
  const { className, message, variant, ...other } = props;
  const Icon = variantIcon[variant];

  const autoHideDuration = variant === "error" ? null : 3500;

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={props.open}
      onClose={props.onClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={TransitionLeft}
    >
      <SnackbarContent
        className={clsx(variant, className)} // Dynamically set the variant
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className="message">
            <Icon className={clsx("icon", "iconVariant")} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={props.onClose}
            size="large"
          >
            <CloseIcon className="icon" />
          </IconButton>,
        ]}
        {...other}
      />
    </StyledSnackbar>
  );
};

export default AppMessageView;
