import React, { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import SimpleBarReact from "simplebar-react";
import "simplebar/src/simplebar.css";
import { CremaTheme } from "types/AppContextPropsType";

const StyledSimpleBarReact = styled(SimpleBarReact)(({ theme }: { theme: CremaTheme }) => ({
  height: "100%",
  width: "100%",
  "& .simplebar-scrollbar::before": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .simplebar-track.simplebar-vertical": {
    backgroundColor: 'transparent',
  },
  "& .simplebar-scrollbar": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100px',
    width: '0.5em',
    "&.simplebar-visible:before": {
      opacity: 1,
    },
  },
  "& .simplebar-content": {
    padding: theme.spacing(2),
  },
}));

interface AppScrollbarProps {
  children: ReactNode;
  className?: string;

  [x: string]: any;
}

const AppScrollbar: React.FC<AppScrollbarProps> = (props) => {
  const { children, ...others } = props;

  return <StyledSimpleBarReact {...others}>{children}</StyledSimpleBarReact>;
};

export default AppScrollbar;
