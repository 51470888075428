import React, { useEffect } from 'react'
import { Box, Divider } from '@mui/material';
import ShipSelect from 'components/ShipSelector';
import Notifications from 'components/Notifications';
import { Ship } from 'types/models/Ship';
import { useAppDispatch } from '../../../../../redux/hooks';
import { getShipList } from "../../../../../redux/actions/Ship";

interface HeaderButtonGroupProps {
  onShipChange: (id: number) => void;
  selectedShip: Ship;
  ships: Ship[];
}

const HeaderButtonGroup: React.FC<HeaderButtonGroupProps> = ({
  onShipChange,
  ships,
  selectedShip,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getShipList())
  }, [dispatch])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.primary',
        borderRadius: 1,
      }}
    >
      <ShipSelect
        onShipChange={onShipChange}
        selectedShip={selectedShip}
        ships={ships}
      />
      <Divider orientation="vertical" flexItem />
      <Notifications />
    </Box>
  )
}

export default HeaderButtonGroup;