import { createSlice, createEntityAdapter, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import { FoulingAreaData, FoulingAreas } from 'types/models/BioFouling';

const foulingAreasAdapter = createEntityAdapter({
    selectId: (area: FoulingAreaData) => area?.areaId,
});

const initialState = foulingAreasAdapter.getInitialState({
    pagination: null,
    status: 'idle',
    updateStatus: 'idle',
    error: null,
    updateError: null
});

const foulingAreasSlice = createSlice({
    name: 'foulingAreas',
    initialState,
    reducers: {
        loadFoulingAreasStart(state) {
            state.status = 'loading';
        },
        loadFoulingAreasSuccess(state, action) {
            const {
                data,
                totalPages,
                totalRecords,
                pageNumber,
                pageSize,
                firstPage,
                lastPage,
                nextPage,
                previousPage,
            } = action.payload;
            foulingAreasAdapter.setAll(state, data);
            state.pagination = { pageNumber, pageSize, totalPages, totalRecords, firstPage, lastPage, nextPage, previousPage };
            state.status = 'succeeded'
        },
        loadFoulingAreasFailure(state, action: PayloadAction<string>) {
            state.status = 'error';
            state.error = action.payload;
        },

        // post - put
        setFoulingAreasStart(state) {
            state.updateStatus = 'loading';
        },
        setFoulingAreasSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        setFoulingAreasFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.updateError = action.payload;
        },

        // delete
        deleteFoulingAreasStart(state) {
            state.updateStatus = 'loading';
        },
        deleteFoulingAreasSuccess(state) {
            state.updateStatus = 'succeeded';
            state.updateError = null;
        },
        deleteFoulingAreasFailure(state, action: PayloadAction<string>) {
            state.updateStatus = 'error';
            state.error = action.payload;
        },

        resetFoulingAreasUpdateStatus(state) {
            state.updateStatus = 'idle';
        },
    }
});

export const {
    loadFoulingAreasStart,
    loadFoulingAreasSuccess,
    loadFoulingAreasFailure,

    setFoulingAreasStart,
    setFoulingAreasSuccess,
    setFoulingAreasFailure,

    deleteFoulingAreasStart,
    deleteFoulingAreasSuccess,
    deleteFoulingAreasFailure,

    resetFoulingAreasUpdateStatus
} = foulingAreasSlice.actions;

// Exporting selectors to access the state
export const foulingAreasSelectors = foulingAreasAdapter.getSelectors<RootState>(
    (state) => state.foulingAreas
);

export default foulingAreasSlice.reducer;