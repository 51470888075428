import React from "react";
import { Stack, SvgIcon, SxProps, Typography, StackProps } from "@mui/material";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useThemeContext } from "@crema/utility/AppContextProvider/ThemeContextProvider";
import { CremaTheme } from 'types/AppContextPropsType';

const CustomCheckedIcon = ({ color, disabled }) => {
    const { theme } = useThemeContext();
    return (
        <SvgIcon viewBox="0 0 18 18" sx={{ width: 18, height: 18 }}>
            <rect width="18" height="18" fill={disabled ? theme.palette.primary.dark : 'white'} />
            <path
                d="M14 5L7 12L4 9"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </SvgIcon>
    );
};

interface CustomCheckBoxOutlineBlankIconProps {
    stroke: string;
}

const CustomCheckBoxOutlineBlankIcon: React.FC<CustomCheckBoxOutlineBlankIconProps> = ({ stroke }) => {
    return (
        <SvgIcon viewBox="0 0 18 18" style={{ width: 18, height: 18 }}>
            <rect
                x="0.5"
                y="0.5"
                width="17"
                height="17"
                stroke={stroke}
                strokeWidth="2"
                fill="none"
            />
        </SvgIcon>
    );
};
interface AppCheckboxProps extends CheckboxProps {
    [x: string]: any;

    containerStyle?: StackProps;
    sxStyle?: SxProps<CremaTheme>;
    stroke: string;
}

const AppCheckbox: React.FC<AppCheckboxProps> = ({
    checked,
    onChange,
    stroke,
    containerStyle,
    sxStyle,
    ...props
}) => {
    const { disabled, label, } = props;
    const { theme } = useThemeContext();
    return (
        <Stack gap={label ? 3 : 0} flexDirection='row' alignItems='center' {...containerStyle}>
            {label && (
                <Typography color={disabled ? 'primary.dark' : 'text.light'} variant="h4">
                    {label}
                </Typography>
            )}
            <Checkbox
                sx={{
                    color: theme.palette.text.light,
                    "&.Mui-checked": {
                        color: disabled ? theme.palette.primary.dark : 'common.white',
                    },
                    "& .MuiSvgIcon-root": {
                        color: disabled ? theme.palette.primary.dark : 'common.white',
                    },
                    ...sxStyle
                }}
                icon={<CustomCheckBoxOutlineBlankIcon stroke={disabled ? theme.palette.primary.dark : stroke} />}
                checkedIcon={<CustomCheckedIcon
                    color={disabled ? theme.palette.background.secondary : theme.palette.background.default}
                    disabled={disabled}
                />}
                onChange={onChange}
                checked={checked}
                {...props}
            />
        </Stack>
    );
};

export default AppCheckbox;
