import React from 'react';
import { SelectBox, CustomMenuItem } from '../index.style';
import IntlMessages from '@crema/utility/IntlMessages';
import { ReactComponent as ArrowDown } from "../../../../assets/icon/arrow-down.svg";

interface IntervalSelectBoxProps {
  intervalType: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const IntervalSelectBox: React.FC<IntervalSelectBoxProps> = ({ intervalType, onChange, isOpen, onOpen, onClose }) => (
  <SelectBox
    sx={{ "& .MuiSelect-select": { color: isOpen ? 'primary.main' : 'text.light', } }}
    onOpen={onOpen}
    onClose={onClose}
    open={isOpen}
    MenuProps={{
      elevation: 0, //no shadow
      sx: {
        '& .MuiPaper-root': {
          mt: 2,
          bgcolor: 'primary.dark',
          backgroundImage: 'none',
          paddingRight: 0,
        },
        '& .MuiMenu-list': { py: 0 },
        '& .MuiOutlinedInput-input': {
          paddingRight: 0,
        }
      }
    }}
    IconComponent={(props) => <ArrowDown className={props.className} />}
    id="simple-select"
    value={intervalType}
    onChange={onChange}
  >
    <CustomMenuItem value={'daily'}><IntlMessages id="common.day" /></CustomMenuItem>
    <CustomMenuItem value={'weekly'}><IntlMessages id="common.week" /></CustomMenuItem>
  </SelectBox>
);

export default IntervalSelectBox;
