import React from "react";
import Zoom from "@mui/material/Zoom";
import { Fonts } from "shared/constants/AppEnums";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import { lighten } from "@mui/material";
import styled from "@emotion/styled";
import { CremaTheme } from "../../../types/AppContextPropsType";
import { useTheme } from "@mui/material/styles";


interface StyledTooltipProps extends TooltipProps {
  mode?: "light" | "dark";
}

const StyledTooltip = styled(({ className, mode, ...props }: StyledTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, mode, arrow }: { theme: CremaTheme, mode?: "light" | "dark", arrow?: boolean }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: mode === "light" ? theme.palette.text.secondary : theme.palette.background.default,
    ...(arrow && {
      "&:before": {
        boxShadow: theme.shadows[1],
      },
    }),
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: mode === "light" ? theme.palette.text.secondary : theme.palette.background.default,
    color: mode === "light" ? theme.palette.text.light : 'white',
    boxShadow: mode === "light" ? theme.shadows[1] : "none",
    fontSize: 12,
    fontWeight: Fonts.REGULAR,
    padding:/*  mode === "light" ? 'inherit' :  */theme.spacing(1, 4),
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="left-start"] .${tooltipClasses.tooltip}`]: {
    ...(mode === 'dark' &&
    {
      marginRight: '1px',
      marginTop: theme.spacing(-2),
    })
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="right-start"] .${tooltipClasses.tooltip}`]: {
    ...(mode === 'dark' &&
    {
      marginLeft: '1px',
      marginTop: theme.spacing(-2),
    })
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom-start"] .${tooltipClasses.tooltip}`]: {
    ...(mode === 'light' &&
    {
      marginTop: theme.spacing(1),
    })
  }
}));

interface AppTooltipProps {
  title: any;
  arrow?: boolean;
  mode?: "light" | "dark";
  placement?:
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";
  children: React.ReactElement<any, any>;
}

const AppTooltip: React.FC<AppTooltipProps> = ({
  title,
  placement = "top",
  arrow = true,
  mode = "light",
  children,
}) => {
  // @ts-ignore
  const { theme } = useTheme();
  return (
    <StyledTooltip
      title={title}
      TransitionComponent={Zoom}
      placement={placement}
      arrow={arrow}
      mode={mode}
      theme={theme}
    >
      {children}
    </StyledTooltip>
  );
};
export default AppTooltip;
