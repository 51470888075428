import {Switch,  alpha } from '@mui/material';
import { styled } from "@mui/material/styles";
import { CremaTheme } from 'types/AppContextPropsType';

const AppSwitch = styled(Switch)(({ theme }: { theme: CremaTheme }) => ({
    '.MuiSwitch-switchBase': {
        color: theme.palette.text.light,
    },
    '.MuiSwitch-track': {
        backgroundColor: 'black',
        '&:hover': {
            backgroundColor: alpha('#0000000', 0.95),
        },
        '&:disabled': {
            backgroundColor: alpha('#0000000', 0.88)
        },
        '&:active': {
            backgroundColor: 'black',
        }
    },
    '.MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.primary.main
    },
    '.MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
    }
}));

export default AppSwitch;